<script>
import Vue from "vue";
import BaseVue from "@frontend/Base.vue";
import Gen from "../helper/Gen";
import GlobalVue from "@helper/Global.vue";

import { CalendarIcon, ClockIcon, MapPinIcon } from "vue-feather-icons";

import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from '@fullcalendar/list';
import interactionPlugin from "@fullcalendar/interaction";

export default {
  extends: BaseVue, GlobalVue,
  components: {
    FullCalendar,
    CalendarIcon,
    ClockIcon,
    MapPinIcon,
  },
  data() {
    return {
      arr: [],
      events: [],
      detail: {},
      heroImage: [],
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, listPlugin],
        // plugins: listPlugin,
        displayEventTime: false,
        fixedWeekCount: false,
        eventColor: "#00BE33",
        initialView: "dayGridMonth",
        themeSystem: "bootstrap",
        buttonText: {
          today: this.$root.isMobile ? "Minggu ini" : "Bulan ini",
        },
        locale: "id",
        eventBackgroundColor: "#5AC540",
        eventBorderColor: "#5AC540",
        eventMouseEnter: function(elem) {
          $(elem.el).tooltip({
            container: "body",
            title: elem.event.title,
            placement: "bottom",
            trigger: "hover",
            html: true,
          });
          $(elem.el).magnificPopup({
            items: {
              src: $("#calendarDetail"),
            },
            type: "inline",
            closeBtnInside: "outside",
            fixedContentPos: true,
            overflowY: "scroll",
          });
        },
        eventClick: (elem) => {
          this.changeDetailEvent(elem);
          $(elem.el).magnificPopup({
            items: {
              src: $("#calendarDetail"),
            },
            type: "inline",
            closeBtnInside: "outside",
            fixedContentPos: true,
            overflowY: "scroll",
          });
        },
      },
    };
  },
  computed: {
    finalOption() {
      for(let x = 0; x < this.events.length; x++){
        let arrOld = new Date(this.events[x].end);
        let arrNew = new Date(arrOld.setDate(arrOld.getDate() + 1));
        this.events[x].end = arrNew;
      }
      this.$set(this.calendarOptions, "events", this.events);
      return this.calendarOptions;
    },
  },
  mounted() {
    this.$set(this.$root, "page", this);
    this.getContactContent();
    this.changeNextPrevBgColor();
    setTimeout(() => {
      $(".fc-daygrid-event").magnificPopup({
        items: {
          src: $("#calendarDetail"),
        },
        type: "inline",
        closeBtnInside: "outside",
        fixedContentPos: true,
        overflowY: "scroll",
      });
    }, 1000);

    const getAPI = this.$refs.fullcalendar.getApi();
    if (window.innerWidth < 768) {
      getAPI.changeView("listWeek");
      $(".fc-list-event-title a").attr("href", "#calendarDetail");
    } else {
      getAPI.changeView("dayGridMonth");
    }
  },
  methods: {
    getContactContent() {
      Gen.apirest("/calendar", {}, (err, resp) => {
        this.events = resp.events;
        this.heroImage = resp.heroImage;
      });
    },
    changeNextPrevBgColor() {
      document.querySelector(".fc-button-group").classList.add("btn-group");
      document
        .querySelector(".fc-button-group")
        .classList.remove("fc-button-group");

      document.querySelectorAll(".fc-button").forEach((el) => {
        el.classList.add("btn", "btn-success");
        el.classList.remove("fc-button-primary", "fc-button");
      });

      document.querySelector(".fc-prev-button span").className = "";

      document
        .querySelector(".fc-prev-button span")
        .classList.add("icon-line-arrow-left");

      document.querySelector(".fc-next-button span").className = "";

      document
        .querySelector(".fc-next-button span")
        .classList.add("icon-line-arrow-right");
    },
    changeDetailEvent(elem) {
      let elmOld = new Date(elem.event.end);
      let elmNew = new Date(elmOld.setDate(elmOld.getDate() - 1));
      this.$set(this.detail, "title", elem.event.title);
      this.$set(this.detail, "start", this.dateIdFormat(elem.event.start));
      this.$set(this.detail, "end", this.dateIdFormat(elmNew));
      this.$set(this.detail, "timeStart", elem.event.start);
      this.$set(this.detail, "timeEnd", elem.event.end);
      this.$set(this.detail, "desc", elem.event._def.extendedProps.description);
      this.$set(
        this.detail,
        "location",
        elem.event._def.extendedProps.location
      );
    },
  },
  watch: {},
};
</script>
<template>
  <div>
    <section
      v-if="!this.$root.isMobile"
      id="page-title"
      :style="`--title-img:url('${uploader(heroImage.img)}')`"
    >
      <div class="container">
        <span class="d-none position-absolute">{{heroImage.alt_img}}</span>
        <div class="vertical-middle">
          <div class="row">
            <div class="col-lg-5 col-md-6">
              <h2 itemprop="alternativeHeadline" class="page__title">Kalender Akademik</h2>
              <ol itemscope itemtype="https://schema.org/BreadcrumbList" class="breadcrumb">
                <li 
                  itemprop="itemListElement"
                  itemscope
                  itemtype="https://schema.org/ListItem"
                  class="breadcrumb-item"
                >
                  <router-link :to="{ name: 'Index' }">Home</router-link>
                </li>
                <li 
                  itemprop="itemListElement"
                  itemscope
                  itemtype="https://schema.org/ListItem"
                  class="breadcrumb-item"
                >
                  Akademik
                </li>
                <li 
                  itemprop="itemListElement"
                  itemscope
                  itemtype="https://schema.org/ListItem"
                  class="breadcrumb-item active" aria-current="page"
                >
                  Kalender Akademik
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      v-else
      id="page-title"
      :style="`--title-img:url('${uploader(heroImage.img_mobile)}')`"
    >
      <div class="container">
        <span class="d-none position-absolute">{{heroImage.alt_img_mobile}}</span>
        <div class="vertical-middle">
          <div class="row">
            <div class="col-lg-5 col-md-6">
              <h2 itemprop="alternativeHeadline" class="page__title">Kalender Akademik</h2>
              <ol itemscope itemtype="https://schema.org/BreadcrumbList" class="breadcrumb">
                <li 
                  itemprop="itemListElement"
                  itemscope
                  itemtype="https://schema.org/ListItem"
                  class="breadcrumb-item"
                >
                  <router-link :to="{ name: 'Index' }">Home</router-link>
                </li>
                <li 
                  itemprop="itemListElement"
                  itemscope
                  itemtype="https://schema.org/ListItem"
                  class="breadcrumb-item"
                >
                  Akademik
                </li>
                <li 
                  itemprop="itemListElement"
                  itemscope
                  itemtype="https://schema.org/ListItem"
                  class="breadcrumb-item active" aria-current="page"
                >
                  Kalender Akademik
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="content">
      <div class="content-wrap">
        <section class="section pt-40 pb-124">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-10" itemscope itemtype="https://schema.org/Event">
                <FullCalendar itemprop="eventSchedule" ref="fullcalendar" :options="finalOption" />
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    <!-- #content end -->

    <!-- <div class="modalContainer"> -->
    <div id="calendarDetail" class="main-mfp mfp-hide">
      <h2 itemprop="alternativeHeadline" class="calendarDetail__title">Detail Jadwal</h2>
      <div class="calendarDetail-body">
        <div class="sch-detail">
          <div class="sch-detail-head">
            <div class="detailHead">
              <calendar-icon></calendar-icon>
            </div>
            <h3 itemprop="name" class="sch-detail__title">
              {{ detail.title }}
            </h3>
            <div itemscope itemtype="https://schema.org/Event" class="row justify-content-center justify-content-md-left mt-4">
              <div class="col-auto">
                  <span v-if="detail.start == detail.end" itemprop="eventSchedule" class="sch-detail__posttitle"
                    ><calendar-icon></calendar-icon>
                    {{ detail.start }}</span
                  >
                  <span v-else itemprop="eventSchedule" class="sch-detail__posttitle"
                    ><calendar-icon></calendar-icon>
                    {{ detail.start }} -
                    {{ detail.end }}</span
                  >
              </div>
              <div class="col-auto">
                <span itemprop="location" class="sch-detail__posttitle"
                  ><map-pin-icon></map-pin-icon> {{ detail.location }}</span
                >
              </div>
            </div>
          </div>
          <div class="sch-detail-body text-center">
            <p itemprop="description">{{ detail.desc }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<style>
.fc-daygrid-event {
  cursor: pointer;
}
.modalContainer {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
}
.fc-daygrid-body,
.fc-scrollgrid-sync-table,
.fc-col-header {
  width: 100% !important;
}
.fc-daygrid-body,
.fc-scrollgrid-sync-table,
.fc-scroller {
  overflow: hidden !important;
}
.fc-toolbar-title{
    font-size: 1.5em !important;
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
</style>
